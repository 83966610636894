import classNames from 'classnames';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import Sources from 'common-ui-components/Sources';
import FollowupQuestions from 'es-src/screens/HomeScreen/components/ResultSection/FollowupQuestions';
import {
  EnrichedEnterpriseSearchResponse,
} from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnrichedSourcesDataHook';
import Disclaimer from 'screens/platform/cross-platform-components/Disclaimer';

import style from 'es-src/screens/HomeScreen/components/ResultSection/EnterpriseSearchResult/components/AnswerSessionContent/style.module.scss';

interface Props {
  data: EnrichedEnterpriseSearchResponse;
}

export default function AnswerSessionContent({
  data,
}: Props) {
  return (
    <>
      <div className={classNames(style.answer, 'answer')}>
        <ReactMarkdown
          linkTarget="_blank"
          className={style.markdown}
        >
          {data.answer}
        </ReactMarkdown>
        <Disclaimer sessionId={data.sessionId} location="Enterprise Search" />
      </div>
      <Sources sources={data.sources} />
      {data.isSuccessfulAnswer && (
        <FollowupQuestions searchResponse={data} />
      )}
    </>
  );
}
