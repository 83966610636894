import React, { MutableRefObject } from 'react';

import Button from 'common-ui-components/Button';
import LoadingDots from 'common-ui-components/LoadingDots';
import Disclaimer from 'screens/platform/cross-platform-components/Disclaimer';

import { ReactComponent as SparklesIcon } from 'assets/img/icon/sparkles-icon.svg';
import { ReactComponent as XIcon } from 'assets/img/icon/x-icon.svg';

import style
  from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/AtomSearchResult/SummarizeTooltip/style.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  tooltipRef: MutableRefObject<HTMLDivElement | null>;
  summaryText: string | undefined;
  sessionId: string | undefined;
  isLoading: boolean;
}

export default function SummarizeTooltip({
  isOpen, onClose, tooltipRef, summaryText, sessionId, isLoading,
}: Props) {
  if (!isOpen) return null;

  return (
    <div ref={tooltipRef} className={style.summarizeTooltipContainer}>
      <div className={style.tooltipHeader}>
        <div className={style.title}>
          <SparklesIcon className={style.sparklesIcon} />
          <span className={style.text}>Summary</span>
        </div>
        <Button transparent onClick={onClose}>
          <XIcon className={style.closeIcon} />
        </Button>
      </div>
      <div className={style.tooltipBody}>
        {isLoading ? (
          <div className={style.loading}>
            <LoadingDots />
          </div>
        ) : summaryText && (
          <>
            <div className={style.answer}>
              {summaryText}
            </div>
            {sessionId && <Disclaimer sessionId={sessionId} location="Atom Summary" />}
          </>
        )}
      </div>
    </div>
  );
}
