import { getRequest, HttpResponse, postRequest } from 'global/api/platformApiHelpers';
import App from 'global/lists/apps';
import { Atom } from 'model/Atom';
import Organization from 'model/Organization';
import Person, { MinimalContact, MinimalPerson } from 'model/Person';
import { SalesforceRecord } from 'model/Salesforce';
import Tag from 'model/Tag';
import Tenant from 'model/Tenant';
import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';

const sourceTypes = ['atom', 'salesforce', 'person', 'organization'] as const;
export type SourceType = typeof sourceTypes[number];

export type AtomSource = {
  type: 'atom';
  data: Atom;
}

export type SalesforceSource = {
  type: 'salesforce';
  data: SalesforceRecord;
}

export type PersonSource = {
  type: 'person';
  data: {
    _id: string;
  };
}

export type OrganizationSource = {
  type: 'organization';
  data: {
    id: string;
  };
}

export type Source = AtomSource | SalesforceSource | PersonSource | OrganizationSource;

export interface CustomFilters {
  datesRange: DatesRange | null;
  includeUntagged: boolean;
  topics?: string[];
  appIds?: App[];
  participantsPersonIds?: string[];
  organizationsIds?: string[];
  directionality?: Directionality;
}

export type Intent = 'SEARCH' | 'QUESTION' | 'TASK' | 'DEFINITION' | 'QUERY';
export type IntentCase = 'PASSIVE' | 'ACTIVE' | 'CLOSE-ENDED';
export type IntentClassification = {
  intent: Intent;
  intentCase: IntentCase;
};

export interface AnswerResponse {
  sources: Source[];
  answer: string;
  sessionId: string;
  isSuccessfulAnswer: boolean;
  intentClassification: IntentClassification;
  prompt?: string;
  cleanedPromptResult?: CleanedPromptResult;
  reframedPromptResult?: ReframedPromptResult;
}

type LimitedSearchResults<T> = {
  results: T[];
  totalResultsCount: number;
}

export type TagWithCategories = {
  tag: string;
  categories: string[];
}

export type SearchResultsResponse = {
  atoms: LimitedSearchResults<Atom>;
  persons: LimitedSearchResults<MinimalPerson>;
  contacts: LimitedSearchResults<MinimalContact>;
  organizations: LimitedSearchResults<Organization>;
  topics: LimitedSearchResults<TagWithCategories>;
  extractedDatesRange?: {
    startDate: number;
    endDate: number;
  };
};

export interface EnterpriseSearchResponse {
  answer: AnswerResponse;
  searchResults: SearchResultsResponse;
}

export type FollowupQuestion = {
  followupQuestion: string;
  shortFollowupQuestion: string;
}

export interface AutoCompleteResponse {
  people: LimitedSearchResults<Person>;
  organizations: LimitedSearchResults<Organization>;
  topics: LimitedSearchResults<Tag>;
}

export type CleanedPromptResult = {
  cleanedPrompt: string;
  originalPrompt: string;
  confidenceScore: number;
};

export type ReframedPromptResult = {
  reframedPrompt: string;
  confidence: number;
  reframeError?: 'tooManySentences';
};

export type QuestionSource =
  | 'userInput'
  | 'followupQuestion'
  | 'suggestedQuestion'
  | 'originalQuestion'
  | 'cleanedQuestion'
  | 'reframedQuestion';

interface EntitySummaryResponse {
  data: string;
  sessionId: string;
  isSuccessfulAnswer: boolean;
}

export function isValidSourceType(sourceType: SourceType) {
  return sourceTypes.includes(sourceType);
}

const EnterpriseSearch = {
  async enterpriseSearch(
    tenantId: Tenant['id'],
    question: string,
    questionSource: QuestionSource,
    signal?: AbortSignal,
    skipPromptCleaning: boolean = false,
    digDeeper: boolean = false,
    executeOnlyRegularSearch: boolean = false,
    datesRange?: {
      startDate: number;
      endDate: number;
    },
    topics?: string[],
    appIds?: App[],
    participantsPersonIds?: string[],
    organizationsIds?: string[],
    directionality?: Directionality,
  ): Promise<HttpResponse<EnterpriseSearchResponse>> {
    return postRequest(
      'enterpriseSearch',
      {
        question,
        questionSource,
        skipPromptCleaning,
        executeOnlyRegularSearch,
        datesRange,
        topics,
        appIds,
        participantsPersonIds,
        organizationsIds,
        directionality,
      },
      { params: { tenantId, digDeeper }, signal },
    );
  },

  async getSuggestedQuestions(tenantId: Tenant['id']): HttpResponse<string[]> {
    return getRequest('enterpriseSearch/suggestedQuestions', { tenantId });
  },

  async getAtomResultSummary(tenantId: Tenant['id'], id: string, appId: App): HttpResponse<{ answer: string; sessionId: string }> {
    return getRequest('atomResult/summary', { tenantId, id, appId });
  },

  async getFollowupQuestions(
    tenantId: Tenant['id'],
    sessionId: string,
    question: string,
    answer: string,
  ): HttpResponse<FollowupQuestion[]> {
    return postRequest(
      'enterpriseSearch/followupQuestions',
      { sessionId, question, answer },
      { params: { tenantId } },
    );
  },

  async getEntitySummary(
    tenantId: Tenant['id'],
    entityType: 'topics' | 'organizations' | 'people',
    entityId: string,
    filters: CustomFilters,
    signal?: AbortSignal,
  ): HttpResponse<EntitySummaryResponse> {
    const { datesRange, ...rest } = filters;
    const customFilters = {
      ...rest,
      datesRange: datesRange ? {
        from: datesRange.from.getTime(),
        to: datesRange.to.getTime(),
      } : {
        from: 0,
        to: new Date().getTime(),
      },
    };

    return postRequest(
      'entity/summary',
      { entityType, entityId, customFilters },
      { params: { tenantId }, signal },
    );
  },

  async getAutoComplete(
    tenantId: Tenant['id'],
    searchTerm: string,
  ): HttpResponse<AutoCompleteResponse> {
    return getRequest('enterpriseSearch/autocomplete', { tenantId, searchTerm });
  },
};

export default EnterpriseSearch;
